import MainSection from "./sections/main/MainSection";
import AboutSection from "./sections/about/AboutSection";
import ContactSection from "./sections/contact/ContactSection";
import React, {useEffect, useRef, useState} from "react";
import {scrollToRef} from "./utils/window";
import Button from "react-bootstrap/Button";
import LogoColor from "./images/logo.svg";
import LogoWhite from "./images/logo_white.svg";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import {useTranslation} from "react-i18next";

import setupAndInitI18n from "./i18n/i18nSetup";
import LanguageToggle from "./components/LanguageToggle";

import ReactGa from 'react-ga';
import PartnersSection from "./sections/partners/PartnersSection";
import FormSection from "./sections/form/FormSection";
import {Helmet} from "react-helmet";

ReactGa.initialize("G-XGE0QBFDMS");
ReactGa.pageview(window.location.pathname + window.location.search);

setupAndInitI18n();

function App() {

  const refMain = useRef();
  const refAbout = useRef();
  const refContact = useRef();
  const refPartners = useRef();
  const refForm = useRef();
  const { t } = useTranslation();
  const [headerMoved, setHeaderMoved] = useState(false)
  const [expand, setExpand] = useState(false)

  const listenScrollEvent = (event) => {
    if (window.scrollY < 150) {
      return setHeaderMoved(false)
    } else if (window.scrollY > 140) {
      return setHeaderMoved(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () =>
        window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  return (
      <div className="App">
        <Helmet>
          <title>Autoverzekering laten vergelijken</title>
          <meta name="description" content="Autoverzekering laten vergelijken"/>
          <meta
              name="keywords"
              content="Prijs verzekering simuleren, Auto, Verzekering, Autoverzekering, Omnium, Schade, Ongeval, Auto ongeval, Auto schade, Auto omnium, Autoverzekering vergelijken, Autoverzekering laten vergelijken, Autoverzekering prijs, Autoverzekering prijs vergelijken, Autoverzekering omnium, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Autoverzekering omnium prijs vergelijken, Autoverzekering omnium vergelijken, Autoverzekering omnium prijs, Makelaar"
          />
        </Helmet>
        <Navbar className={`main-nav ${headerMoved ? 'header-moved' : ''}`} expand="lg" fixed="top" expanded={expand} onToggle={() => setExpand(!expand)}>
          <Container>
            <Navbar.Brand href="#home" onClick={() => scrollToRef(refMain)}>
              <img src={headerMoved ? LogoWhite : LogoColor} id="MainLogo" alt="Logo Financieel Adviesburo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
              </Nav>
              <Nav>
                <Nav.Link onClick={() => {
                  scrollToRef(refAbout);
                  setExpand(false);
                }}>{t('about.nav')}</Nav.Link>
                <Nav.Link onClick={() => {
                  scrollToRef(refPartners);
                  setExpand(false);
                }}>{t('partners.nav')}</Nav.Link>
                <Nav.Link onClick={() => {
                  scrollToRef(refContact);
                  setExpand(false)
                }}>{t('contact.nav')}</Nav.Link>
                <LanguageToggle onClick={() => setExpand(false)} />
                <Button variant="primary" onClick={() => {
                  scrollToRef(refForm);
                  setExpand(false)
                }}>{t('form.nav')}</Button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <MainSection reference={refMain} toAbout={() => scrollToRef(refAbout)} toForm={() => scrollToRef(refForm)} />
        <AboutSection reference={refAbout} />
        <FormSection reference={refForm} />
        <PartnersSection reference={refPartners} />
        <ContactSection reference={refContact} />
      </div>
  );
}

export default App;
